<template>
  <div>
    <div class="row" style="justify-content: left; margin: 0; padding: 0">
      <v-col class="col-space" cols="4" sm="4" md="4" style="margin-right: 0">
            <treeselect
              :multiple="true"
              :limit="3"
              placeholder="Filter Games"
              :options="getAllAppMenu"
              v-model="menuValue"
              :clearable="false"
              @input="selectMenu(menuValue)"
            />
      </v-col>
      <v-col cols="4" sm="4" md="4" class="text-right pl-3 col-space" style="margin-right: 0px">
        <v-select
          v-model="currencyType"
          :items="items2"
          item-value="id"
          item-text="name"
          label="Channel #  "
          class="currency-type"
          style="min-width: 100%"
          @input="selectCurrencyType(currencyType)"
          outlined
        >
        </v-select>
      </v-col>
      <v-col class="col-space" cols="4" sm="4" md="4" style="margin-right: 0">
        <v-text-field
          v-model="v_price"
          append-icon="mdi-magnify"
          label="Price"
          single-line
          hide-details
          clearable
          type="number"
          class="pl-5"
        />
        <!-- <v-text-field
          v-model="v_search"
          append-icon="mdi-magnify"
          label="Group"
          single-line
          hide-details
          clearable
          class="pl-5"
        /> -->
      </v-col>
    </div>
    <div class="row" style="justify-content: left; margin: 0; padding: 0">
      <div class="col-md-5 col-sm-5 bottom-space">
        <v-menu
          v-model="v_startDate"
          :close-on-content-click="false"
          :nudge-right="100"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="pl-5"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              :label="$t('header.start')"
              readonly
              v-bind="attrs"
              v-on="on"
              filled
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="startDate" @input="v_startDate = false">
          </v-date-picker>
        </v-menu>
      </div>
      <div class="col-md-5 col-sm-5 bottom-space">
        <v-menu
          v-model="v_endDate"
          :close-on-content-click="false"
          :nudge-right="100"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              :label="$t('header.finish')"
              readonly
              v-bind="attrs"
              v-on="on"
              filled
            >
            </v-text-field>
          </template>
          <v-date-picker
            v-model="endDate"
            @input="v_endDate = false"
          ></v-date-picker>
        </v-menu>
      </div>
      <!-- <div class="col-md-3 col-sm-3 bottom-space" style="margin-top: 0px;">
        <v-text-field
          v-model="v_price"
          append-icon="mdi-magnify"
          label="Price"
          single-line
          hide-details
          clearable
          type="number"
          class="pl-5"
        />
      </div> -->
      <div class="col-md-2 col-sm-2 bottom-space" style="margin-top: 10px;">
        <v-btn
          class="white--text btn-custom mr-5"
          color="primary darken-1"
          depressed
          @click="getData()">
          SEARCH
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

// import the component
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: { Treeselect },
  props: [
    "darkmode",
    "status",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "time",
    "getAllAppMenu",
  ],
  data() {
    return {
      data: {
        descending: false,
        sortBy: "amount_win",
        page: 1,
        rowsPerPage: 10,
        fields: [
        "_id.com",
        "_id.app_id",
        "amount",
        "amount_win"
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
        channelType: [0],
        currencyType: 1,
        price:1
      },
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      v_search: "",
      v_price: 0,
      // items: [
      //   { name: "CO1", id: 1 },
      //   { name: "CO2", id: 2 },
      //   { name: "CO3", id: 3 },
      //   { name: "CO4", id: 4 },
      //   { name: "CO5", id: 15 },
      //   { name: "CO6", id: 16},
      //   { name: "KL1", id: 5 },
      //   { name: "BA1", id: 6 },
      //   { name: "BA2", id: 7 },
      //   { name: "BA3", id: 18 },
      //   { name: "TD1", id: 8 },
      //   { name: "TD2", id: 9 },
      //   { name: "TD3", id: 19 },
      //   { name: "LO1", id: 10 },
      //   { name: "YU1", id: 11 },
      //   { name: "AP1", id: 12 },
      //   { name: "AP2", id: 17 },
      //   { name: "FT1", id: 13 },
      //   { name: "TS1", id: 14 },
      //   { name: "HAN2", id: 20 },
      //   { name: "HAN3", id: 21 },
      //   { name: "Loto(SLOT)", id: 90},
      //   { name: "Yuki(SLOT)", id: 91},
      //   { name: "PP78(SLOT)", id: 92},
      //   { name: "KL(SLOT)", id: 93},
      //   { name: "TD(SLOT)", id: 94},
      //   { name: "BA(SLOT)", id: 95},
      //   { name: "BA(SLOT)", id: 95},
      //   { name: "DODEN(SLOT)", id: 96},
      //   { name: "SICBO(SLOT)", id: 97},
      //   { name: "TAIXIU(SLOT)", id: 98},
      // ],
      channelType: [0],
      items2: [
        { name: "ដុល្លា", id: 1 },
        { name: "រៀល", id: 2 },
        { name: "ដុង", id: 4 },
        { name: "រ៉ូពី", id: 5 },
      ],
      currencyType: 1,
      startTime: "00:00",
      endTime: "23:59",
      menuValue: [0],
      selectedMenu: []
    };
  },
  watch: {
    time: function (newVal) {
      // Emit this information to the parents component
      this.startDate = newVal.startDate;
      this.endDate = newVal.endDate;
    },
  },
  mounted() {
    this.$cookies.set("currency-type", this.currencyType);
  },
  computed: {
    titleInfo: function () {
      return "List Top Range Winner";
    },
    reportUrl: function () {
      return "getViewTopRangeWinnerApiBranch";
    },
  },
  methods: {
    selectMenu(menuValue) {
      this.menuValue = menuValue;
    },
    selectCurrencyType(currencyType) {
      this.$cookies.set("currency-type", currencyType);
      this.currencyType = parseInt(this.$cookies.get("currency-type"));
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    getSelectedMenu(){
      let all = ["1", "2", "3", "4","5", "6", "7", "8", "9", "10", "11", "12", "13", "14","15", "16", "17", "18", "19", "20","21", 
                 "90", "901", "902","91", "911", "912", "92", "921", "922", 
                 "93", "931", "932", "933", "934",
                 "94","941", "942", "943", "944", "945", "946", "947", "948", "949",
                 "95","951", "952", "953", "954", "955", "956", "957", "958", "959",
                 "96", "961", "962", "963", "964","97","971", "972", "973", "974",
                 "98","981", "982", "983", "984", "99", "991", "992"];
      let cock = ["1", "2", "3", "4","15", "16"];
      let casino = ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14","17", "18", "19", "20","21"];
      let lotto_game = ["90", "901", "902","91", "911", "912", "92", "921", "922", "99", "991", "992"];
      let lotto = ["90", "901", "902"], yuki = ["91", "911", "912"], lottery= ["92", "921", "922"], keno = ["99", "991", "992"];
      let machine_game = ["93", "931", "932", "933", "934","94","941", "942", "943", "944", "945", "946", "947", "948", "949",
                           "95","951", "952", "953", "954", "955", "956", "957", "958", "959",
                           "96", "961", "962", "963", "964","97","971", "972", "973", "974", "98","981", "982", "983", "984"];
      let dragon = ["94","941", "942", "943", "944", "945", "946", "947", "948", "949"];
      let baccarat = ["95","951", "952", "953", "954", "955", "956", "957", "958", "959"];
      let taixiu = ["98","981", "982", "983", "984"], doden = ["96", "961", "962", "963", "964"];
      let sicbo = ["97","971", "972", "973", "974"], klaklouk = ["93", "931", "932", "933", "934"];
      if(this.menuValue.length > 0){
        this.selectedMenu = [];
        for(let i in this.menuValue){
          if(this.menuValue[i] == 0){
            this.selectedMenu = all;
          }else if(this.menuValue[i] == 'cock'){
            this.selectedMenu = [...this.selectedMenu, ...cock];
          }else if(this.menuValue[i] == 'casino'){
            this.selectedMenu = [...this.selectedMenu, ...casino];
          }else if(this.menuValue[i] == 'lotto_game'){
            this.selectedMenu = [...this.selectedMenu, ...lotto_game];
          }else if(this.menuValue[i] == 'lotto'){
            this.selectedMenu = [...this.selectedMenu, ...lotto];
          }else if(this.menuValue[i] == 'yuki'){
            this.selectedMenu = [...this.selectedMenu, ...yuki];
          }else if(this.menuValue[i] == 'lottery'){
            this.selectedMenu = [...this.selectedMenu, ...lottery];
          }else if(this.menuValue[i] == 'keno'){
            this.selectedMenu = [...this.selectedMenu, ...keno];
          }else if(this.menuValue[i] == 'machine_game'){
            this.selectedMenu = [...this.selectedMenu, ...machine_game];
          }else if(this.menuValue[i] == 'dragon'){
            this.selectedMenu = [...this.selectedMenu, ...dragon];
          }else if(this.menuValue[i] == 'baccarat'){
            this.selectedMenu = [...this.selectedMenu, ...baccarat];
          }else if(this.menuValue[i] == 'taixiu'){
            this.selectedMenu = [...this.selectedMenu, ...taixiu];
          }else if(this.menuValue[i] == 'doden'){
            this.selectedMenu = [...this.selectedMenu, ...doden];
          }else if(this.menuValue[i] == 'sicbo'){
            this.selectedMenu = [...this.selectedMenu, ...sicbo];
          }else if(this.menuValue[i] == 'klaklouk'){
            this.selectedMenu = [...this.selectedMenu, ...klaklouk];
          }else {
            this.selectedMenu.push(this.menuValue[i].toString());
          }
        }
      }else{
        this.selectedMenu = all;
      }
      return this.selectedMenu;
    },
    async getData() {
      let formatStartDate = this.startDate + "T" + this.startTime + ":00";
      let formatEndDate = this.endDate + "T" + this.endTime + ":59";
      this.data.startDate = formatStartDate;
      this.data.endDate = formatEndDate;
      this.data.search = this.v_search;
      this.data.price = this.v_price ? parseInt(this.v_price) : 0;
      this.data.channelType = this.getSelectedMenu();
      this.data.currencyType = this.currencyType;
      // console.log("this.reportUrl:",this.reportUrl);
      let preData = {
        data: this.data,
        user_id: this.user._id,
        reportUrl: this.reportUrl,
      };
      console.log('preData:', preData);
      this.fetchListStatement(preData).then(() => {
        // console.log("into fetch list statement");
        let date = {
          startDate: this.startDate,
          endDate: this.endDate,
          price: this.v_price ? parseInt(this.v_price) : 0,
          search: this.v_search ? this.v_search : "",
          channelType: this.data.channelType
        };
        this.$emit("child-checkbox", date);
      });
    },
    ...mapActions("$_statementTopRangeWinnerReports", [
      "fetchListStatement",
      "fetchChannelType",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.col-space {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .bottom-space {
    margin-top: 0;
    padding-top: 0;
  }
.btn-custom {
  position: relative;
  // right: 250px;
  top: 10px;
  padding: 15px !important;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 15px !important;
}
@media (max-width: 768px) {
  .v-menu__content {
    left: 63px !important;
  }
}
</style>
